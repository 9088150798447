@import "styles/common.scss";
.root {
  position: relative;
  display: flex;
  flex: 1 1 0;
  justify-content: space-between;
  min-width: 1px;
}

.hamburgerButton {
  flex: none;
  margin-left: auto;
}

.nav {
  display: flex;
}

.fixed {
  position: fixed;
  top: var(--header-height);
  right: 0;
  bottom: 100%;
  left: 0;
  overflow: hidden;
  background: $color-white;
  border-top: 1px solid $color-gray-800;
  transition: bottom 200ms ease;
}

.opened {
  bottom: 0;
  overflow: auto;
}

@include media-mobile {

  .nav {
    flex-direction: column;
    align-items: stretch;
  }
}

@include media-except-mobile {

  .nav {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
  }
}
