@import "styles/common.scss";
.item {
  padding: 4px 16px;
  cursor: pointer;
}

.focused {
  background: rgba($primary-color, 0.2);
}

.selected {
  background: rgba($primary-color, 0.7);
}
