@import "styles/common.scss";
.transfer {
  background-color: #FFFECB;
}

.deposition {
  background-color: #D8FFB3;
}

.infoContainer {
  flex: 1 1 0;
}

.amountContainer {
  flex: none;
  margin-left: 16px;
  text-align: right;
}
