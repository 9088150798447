@import "styles/common.scss";
.root {
  display: block;
  margin: 20px 0;
}

.header {
  padding: 10px 20px;
  color: #610c09;
  font-weight: bold;
  font-size: 0.9em;
  background: #ff8a78;
}

.pre {
  margin: 0;
  padding: 10px 20px;
  color: $text-color;
  font-size: 0.9em;
  background: #ffedf0;
}
