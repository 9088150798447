@import "styles/common.scss";
.item {
  margin-left: 16px;
  background-color: #dbf0ff;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
