@import "styles/common.scss";
.root {
  position: relative;
}

.menu {
  position: absolute;
  z-index: $zindex-dropdown;
  padding: 0 0 $offset-16;
  background: $color-white;
  border: 1px solid $border-color;
  border-top-width: 0;
  border-radius: 0 0 $border-radius $border-radius;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
