@import "styles/common.scss";
.value {
  position: relative;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.wrap.wrap {
  white-space: normal;
}
