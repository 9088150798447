@import "styles/common.scss";
.root {
  display: inline-flex;
  align-items: center;
  line-height: 24px;
  white-space: nowrap;
  background-color: $color-gray-700;
  border-radius: 24px;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}

.label {
  flex: 1 1 0;
  min-width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.removeButton {
  flex: none;
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.textWhite {
  color: $color-white;
}

.textBlack {
  color: $color-dark;
}

.preset-magenta {
  background-color: #ffe2fe;
}
