@import "styles/common.scss";
.root {
  display: inline-block;
  height: $control-height-md;
  padding: 0 15px;
  font-weight: 400;
  font-size: 0.95rem;
  vertical-align: middle;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: all 100ms linear;
  user-select: none;

  &:after {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    display: none;
    background-color: rgba(255, 255, 255, 0.4);
    content: '';
    pointer-events: none;
  }
}

.disabled {
  position: relative;
  cursor: not-allowed;

  &:after {
    display: block;
  }
}

a.disabled {
  pointer-events: none;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.loader {
  flex: none;
  height: 60%;
  margin-right: 12px;
}

.block {
  display: block;
  width: 100%;
}

.loading {
  //
}

// Sizes

.sm {
  height: $control-height-sm;
}

.md {
  height: $control-height-md;
}

.lg {
  height: $control-height-lg;
}

.rounded {
  &.sm {
    border-radius: $control-height-sm;
  }

  &.md {
    border-radius: $control-height-md;
  }

  &.lg {
    border-radius: $control-height-lg;
  }
}

// Presets

.default {
  color: rgba(0, 0, 0, 0.8);
  background-color: #ffffff;
  border-color: $color-gray-600;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: lighten($primary-color, 10%);
    border-color: lighten($primary-color, 10%);
  }

  &:not(:disabled):active {
    color: $primary-color;
    border-color: $primary-color;
  }
}

.primary {
  color: #ffffff;
  background-color: $primary-color;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: lighten($primary-color, 10%);
  }

  &:not(:disabled):active {
    background-color: darken($primary-color, 10%);
  }
}

.danger {
  color: $error-color;
  background-color: #fff;
  border-color: $border-color;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: #fff;
    background-color: $error-color;
    border-color: transparent;
  }

  &:not(:disabled):active {
    color: #fff;
    background-color: darken($error-color, 10%);
    border-color: transparent;
  }
}
