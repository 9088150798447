@import "styles/common.scss";
@include media-mobile {

  .product,
  .title,
  .price,
  .count,
  .amount,
  .categories {
    margin: 4px 0;
  }

  .product {
    flex: 0 0 100%;
  }

  .title {
    flex: 0 0 100%;
  }

  .price,
  .count,
  .amount {
    width: calc(33% - 4px);
  }

  .count {
    margin-right: 8px;
    margin-left: 8px;
  }

  .categories {
    flex: 0 0 calc(100% - 48px);
    margin-right: 8px;
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-tablet-landscape - 1px) {

  .product,
  .title,
  .price,
  .count,
  .amount,
  .categories {
    margin: 4px 0;
  }

  .product {
    flex: 0 0 calc(50% - 4px);
    margin-right: 4px;
  }

  .title {
    flex: 0 0 calc(50% - 4px);
    margin-left: 4px;
  }

  .price,
  .count,
  .amount {
    width: calc(33% - 4px);
  }

  .count {
    margin-right: 8px;
    margin-left: 8px;
  }

  .categories {
    flex: 0 0 calc(100% - 48px);
    margin-right: 8px;
  }
}

@media (min-width: $breakpoint-tablet-landscape) {

  .product,
  .title,
  .price,
  .count,
  .amount,
  .categories {
    margin: 4px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .product {
    flex: 1 1 200px;
    max-width: 300px;
  }

  .title {
    flex: 1 1 300px;
  }

  .price,
  .count,
  .amount {
    flex: none;
    width: 100px;
  }

  .categories {
    flex: 1 1 300px;
  }
}
