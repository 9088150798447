$font-family-base: Roboto, -apple-system, Arial, sans-serif;
$font-size-base: 16px;
$font-size-sm: 14px;

$container-max-width: 1280px;

$text-color: rgba(0, 0, 0, 0.9);
$text-secondary-color: rgba(0, 0, 0, .45);
$disabled-color: rgba(0, 0, 0, .25);
$border-color: #bbbbbb;
$light-border-color: #e8e8e8;
$white-color: #ffffff;

$primary-color: #1890ff;
$success-color: #52c41a;
$warning-color: #faad14;
$error-color: #f5222d;

$color-gray-100: #212121;
$color-gray-200: #424242;
$color-gray-300: #616161;
$color-gray-400: #757575;
$color-gray-500: #9e9e9e;
$color-gray-600: #bdbdbd;
$color-gray-700: #e0e0e0;
$color-gray-800: #eeeeee;
$color-gray-900: #f5f5f5;

// NEW

$color-green: #BDEB79;
$color-dark: #43493A;
$color-gray: #A7AE9C;
$color-light-gray: #F5F6F9;
$color-cyan: #00DBFC;
$color-blue: #00A3C3;
$color-white: #ffffff;
$color-black: #111111;

$color-sberbank: #2b9e3a;
$color-tinkoff: #fedc4f;
$color-cash: #515663;

// Z-index

$zindex-dropdown: 100;
$zindex-modal: 500;

// Offsets

$offset-4: 4px;
$offset-8: 8px;
$offset-16: 16px;
$offset-24: 24px;
$offset-32: 32px;
$offset-40: 40px;
$offset-48: 48px;
$offset-56: 56px;

$offsets: (
  4: $offset-4,
  8: $offset-8,
  16: $offset-16,
  24: $offset-24,
  32: $offset-32,
  40: $offset-40,
  48: $offset-48,
  56: $offset-56,
);

$control-height-sm: $offset-32;
$control-height-md: $offset-40;
$control-height-lg: $offset-48;

$border-radius: 4px;
