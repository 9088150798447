@import "styles/common.scss";
@include media-except-mobile {

  .root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 #{- $offset-8} $offset-24;
  }

  .item {
    flex: 1 1 auto;
    margin: 0 $offset-8;
  }
}
