@import "styles/common.scss";
html {
  color: $text-color;
  font-size: $font-size-base;
  font-family: $font-family-base;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background: $color-light-gray;

  @include media-mobile {
    font-size: $font-size-base * 0.875;
  }
}

input, textarea, button, select {
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

button {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
