@import "styles/common.scss";
.root {
  display: block;
  margin: $offset-8 0;
}

a.root {
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}
