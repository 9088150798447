@import "styles/common.scss";
.item {
  display: block;
  flex: none;
  line-height: 1;
  text-align: center;
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  outline: none;
  cursor: pointer;

  &:enabled {
    &:focus, &:hover {
      color: lighten($primary-color, 20%);
      border-color: lighten($primary-color, 20%);
    }

    &:active {
      color: $primary-color;
      border-color: $primary-color;
    }
  }
}

.active {
  color: $primary-color;
  border-color: $primary-color;
}

.disabled {
  color: $disabled-color;
  cursor: not-allowed;
}

.delimiter {
  color: $disabled-color;
  border-width: 0;
}

@include media-mobile {

  .item {
    min-width: $offset-32;
    height: $offset-32;
    margin: $offset-4;
    padding: 0 $offset-8;
  }
}

@include media-except-mobile {

  .item {
    min-width: $offset-40;
    height: $offset-40;
    margin: $offset-4;
    padding: 0 $offset-8;
  }
}
