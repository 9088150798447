@import "styles/common.scss";
.root {
  position: relative;
}

.center {
  margin-right: auto;
  margin-left: auto;
}

.horizontalPadding {

  @include media-mobile {
    padding-right: $offset-8;
    padding-left: $offset-8;
  }

  @include media-tablet {
    padding-right: $offset-24;
    padding-left: $offset-24;
  }

  @include media-desktop {
    padding-right: $offset-32;
    padding-left: $offset-32;
  }
}

.verticalPadding {

  @include media-mobile {
    padding-top: $offset-8;
    padding-bottom: $offset-8;
  }

  @include media-tablet {
    padding-top: $offset-16;
    padding-bottom: $offset-16;
  }

  @include media-desktop {
    padding-top: $offset-24;
    padding-bottom: $offset-24;
  }
}
