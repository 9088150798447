@import "styles/common.scss";
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100vh;
  overflow: hidden;
}

.content {
  position: relative;
  flex: 1 1 0;
  min-height: 1px;
  overflow: auto;
}
