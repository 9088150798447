@import "styles/common.scss";
.root {

  :global {

    .DayPicker-wrapper {
      outline: none;
    }

    .DayPicker-Day {
      line-height: 18px;
    }
  }
}
