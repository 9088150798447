@import "styles/common.scss";
.root {
  // appearance: none;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}
