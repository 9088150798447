@import "styles/common.scss";
.root {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  transition: all 100ms linear;
}

.rounded {
  background: $color-white;
  border-radius: $control-height-md;
}

.hasFocus,
.root:focus-within {
  border-color: $primary-color;
}

.hasError {
  border-color: $error-color;
}

.input {
  flex: 1 1 0;
  min-width: 1px;
  height: $control-height-md;
  min-height: 100%;
  padding: 8px 10px;
  background: transparent;
  border: none;
  outline: none;

  .rounded & {
    padding-right: 16px;
    padding-left: 16px;
  }
}

textarea.input {
  height: auto;
  min-height: $control-height-md;
}
