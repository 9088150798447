@import "styles/common.scss";
.h1 {
  font-size: 32px;
}

.h2 {
  font-size: 24px;
}

.sm {
  font-size: 12px;
}

.md {
  font-size: 18px;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.left {
  text-align: center;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.lineThrough {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.muted {
  color: $text-secondary-color;
}

.uppercase {
  text-transform: uppercase;
}
