@import "styles/common.scss";
@include media-mobile {

  .navItem {
    display: block;
    padding: $offset-16;
    font-size: 16px;

    &:after {
      position: relative;
      bottom: -$offset-16;
      display: block;
      height: 0;
      border-bottom: 1px solid $color-gray-700;
      content: '';
    }
  }

  .link {
    display: block;
  }

  .activeLink {
    color: $color-blue;
  }
}

@include media-except-mobile {

  .navItem {
    display: inline-block;
    margin-left: $offset-24;
    font-weight: 300;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }
  }

  .link {
    display: inline-block;

    &:after {
      display: block;
      height: 2px;
      margin-top: -2px;
      transition: background-color 100ms linear;
      content: '';
    }

    &:hover {
      &:after {
        background-color: $color-green;
      }
    }
  }

  .activeLink {
    &:after {
      background-color: $color-blue;
    }
  }
}
