@import "styles/common.scss";
.root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow: auto;
}
