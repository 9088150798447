@import "styles/common.scss";
.root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

.loader {
  height: 50px;
}
