@import "styles/common.scss";
.table {
  width: 100%;
  margin: 0;
  padding: 0;
  //table-layout: fixed;
  border: none;
}

.tr {
  border-top: 1px solid rgba($color-gray-800, 0.9);

  &:hover {
    background-color: rgba($color-gray-800, 0.5);
  }
}

.th {
  padding: $offset-8 $offset-8 $offset-4;
}

.td {
  padding: $offset-8;
}

.th, .td {
  white-space: nowrap;

  &:first-child {
    padding-left: $offset-16;
  }

  &:last-child {
    padding-right: $offset-16;
  }
}
