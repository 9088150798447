@import "styles/common.scss";
.root {
  width: $offset-32;
  height: $offset-32;
  padding: 0;
  color: $disabled-color;
  line-height: 0;
  outline: none;
  transition: all 200ms ease;

  &:focus, &:hover {
    color: $text-color
  }

  &:active {
    color: $primary-color;
  }
}

.icon {
  width: $offset-16;
  height: $offset-16;
}
