@import "styles/common.scss";
.block {
  display: flex;
}

.inline {
  display: inline-flex;
}

.wrap {
  flex-wrap: wrap;
}

.justifyContent-flex-start {
  justify-content: flex-start;
}

.justifyContent-flex-end {
  justify-content: flex-end;
}

.justifyContent-center {
  justify-content: center;
}

.justifyContent-space-between {
  justify-content: space-between;
}

.alignItems-flex-start {
  align-items: flex-start;
}

.alignItems-flex-end {
  align-items: flex-end;
}

.alignItems-center {
  align-items: center;
}

.alignItems-stretch {
  align-items: stretch;
}
