@import "styles/common.scss";
.root {
  position: relative;
  border-bottom: 1px solid $light-border-color;
}

.container {
  padding-right: $offset-56;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: $offset-56;
  height: $offset-56;
}

