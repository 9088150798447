@import "styles/common.scss";
.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button {
  margin-left: $offset-8;

  &:first-child {
    margin-left: 0;
  }
}
