@import "styles/common.scss";
.root {
  margin: 0.5rem 0;
  color: red;
  font-size: 0.9em;
}

.isSummary {
  margin: 1rem 0;
  text-align: center;
}
