@import "styles/common.scss";
.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);
}

.modal {
  position: relative;
  flex: none;
  width: 100%;
  min-height: 100%;
  background: #fff;
}

@include media-except-mobile {

  .modal {
    top: 120px;
    max-width: 80%;
    min-height: auto;
    border-radius: $border-radius;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.5);
  }

  .size-sm {
    width: 480px
  }

  .size-md {
    width: 720px
  }

  .size-full {
    top: 0;
    width: calc(100% - 32px);
    max-width: 1900px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

:global(.modal-open) {
  overflow: hidden;
}
