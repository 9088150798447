@import "styles/common.scss";
.root {
  position: relative;
  display: inline-block;
  line-height: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  pointer-events: none;
}

.isVisible {
  opacity: 1;
}

.svg {
  max-width: 100%;
  max-height: 100%;
  animation: loader-rotate 1.5s linear infinite;
}

.widthBased {
  width: 100%;
  height: auto;
}

.heightBased {
  width: auto;
  height: 100%;
}

.circle {
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: loader-circle 1.3s ease-in-out infinite;
}

@keyframes loader-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader-circle {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120;
  }
}
