@import "styles/common.scss";
.table {
  width: 400px;
  max-width: 100%;
  margin-top: $offset-16;
  empty-cells: show;

  tr:nth-child(2n) {
    background-color: $color-gray-800;
  }

  td {
    padding: 2px 5px;

    &:nth-child(2),
    &:nth-child(3) {
      white-space: nowrap;
    }
  }
}
