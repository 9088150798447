@import "styles/common.scss";
.transfer {
  background-color: #FFFECB;
}

.deposition {
  background-color: #D8FFB3;
}

.datetime {
  width: 100px;
}

.account {
  width: 170px;
  max-width: 150px;
}

.title {
  min-width: 30%;
}

.info {
  //max-width: 400px;
}

.amount {
  width: 150px;
}

.balance {
  width: 150px;

  @include media-tablet {
    display: none;
  }
}
