@import "styles/common.scss";
$offset-keys: (
  mt: (margin-top),
  mb: (margin-bottom),
  ml: (margin-left),
  mr: (margin-right),
  m: (margin),
  pt: (padding-top),
  pb: (padding-bottom),
  pl: (padding-left),
  pr: (padding-right),
  p: (padding),
  ptb: (padding-top, padding-bottom),
  plr: (padding-left, padding-right),
  mtb: (margin-top, margin-bottom),
  mlr: (margin-left, margin-right),
);

$auto-keys: (
  mt: (margin-top),
  mb: (margin-bottom),
  mr: (margin-right),
  ml: (margin-left),
  mtb: (margin-top, margin-bottom),
  mlr: (margin-left, margin-right),
);

@each $name, $props in $offset-keys {
  @each $offset, $offset-value in $offsets {
    .#{$name}-#{$offset} {
      @each $prop in $props {
        #{$prop}: $offset-value;
      }
    }
  }
}

@each $name, $props in $auto-keys {
  .#{$name}-auto {
    @each $prop in $props {
      #{$prop}: auto;
    }
  }
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.inline {
  display: inline-block;
}
