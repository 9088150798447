@import "styles/common.scss";
.root {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  transition: all 100ms linear;
}

.rounded {
  background: $color-white;
  border-radius: $control-height-md;
}

.hasFocus,
.root:focus-within {
  border-color: $primary-color;
}

.hasError {
  border-color: $error-color;
}

.control {
  display: flex;
  flex: 1 1 0;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 1px;
  min-height: $control-height-md;
  outline: none;
}

.controlLeftSide {
  display: flex;
  flex: 1 1 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  min-width: 1px;
  padding: 4px 10px;
  outline: none;

  .rounded & {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.controlRightSide {
  display: flex;
  flex: none;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.searchInput {
  padding: 0;
  background: none;
  border: none;
  outline: none;
  appearance: none;
}

.placeholder {
  position: absolute;
}

.clearButton {
  flex: none;
  width: 20px;
  height: 20px;
  color: $color-gray-500;

  &:hover,
  &:focus {
    color: $color-gray-300;
  }
}

.chevron {
  flex: none;
  margin: 0 8px 0 8px;

  .rounded & {
    margin-right: 16px;
  }
}

.loader {
  flex: none;
  width: 24px;
  height: 24px;
  margin: 0;
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: $zindex-dropdown;
  max-height: 300px;
  margin-top: 1px;
  overflow: auto;
  border: 1px solid $border-color;
  border-top-width: 0;
  border-radius: 0 0 $border-radius $border-radius;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

  .rounded & {
    margin-top: 8px;
    border-top-width: 1px;
    border-radius: $border-radius;
  }
}

.selectedSingle {
  position: absolute;
}
