@import "styles/common.scss";
.logoContainer {
  display: block;
  flex: none;
  width: var(--header-height);
  background: $color-green;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: $color-dark;
  font-weight: 800;
  font-size: calc(var(--header-height) * 0.5);
}

