@import "styles/common.scss";
.title {
  color: rgba(67, 73, 58, 0.50);
  font-size: 10px;
  text-transform: uppercase;
}

.wrap {
  white-space: normal;
}
