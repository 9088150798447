@import "styles/common.scss";
.root {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  height: var(--header-height);
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);
}

.logoContainer {
  display: block;
  flex: none;
  background: $color-green;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: $color-dark;
  font-weight: 800;
  font-size: 40px;
}

.navContainer {
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: flex-start;
  min-width: 1px;
  background: $color-white;
}

.nav {
  margin-right: auto;
}

body {
  --header-height: 56px;
}

@include media-except-mobile {

  body {
    --header-height: 80px;
  }
}
