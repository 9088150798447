@import "styles/common.scss";
.root {
  display: inline-block;
  padding: 0;
  border: none;
  border-radius: 50%;
  outline: none;
  transition: all 100ms linear;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.icon {
  flex: none;
}

.size-sm {
  width: $offset-32;
  height: $offset-32;
}

.size-md {
  width: $offset-40;
  height: $offset-40;
}

.size-lg {
  width: $offset-56;
  height: $offset-56;
}

.default {
  color: $color-dark;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    // color: $color-white;
    background-color: $color-green;
  }

  &:not(:disabled):active {
    background-color: darken($color-green, 10%);
  }
}

.gray {
  color: $color-dark;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: $color-gray-200;
    background-color: $color-gray-700;
  }

  &:not(:disabled):active {
    background-color: $color-gray-600;
  }
}

.danger {
  color: $color-dark;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: $error-color;
  }

  &:not(:disabled):active {
    color: $error-color;
  }
}
