@import "styles/common.scss";
.root {
  position: relative;
  display: inline-flex;
  cursor: pointer;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: none;
  visibility: hidden;
  opacity: 0;
}

.input:checked + .track {
  background: $primary-color;

  .handle {
    transform: translateX($control-height-sm * 0.8);
  }
}

.input:disabled + .track {
  cursor: not-allowed;
  opacity: 0.5;
}

.track {
  position: relative;
  display: block;
  width: $control-height-sm * 1.8;
  height: $control-height-sm;
  background: $color-gray-400;
  border-radius: $control-height-sm;
  transition: all 200ms ease;
}

.handle {
  display: block;
  width: $control-height-sm - 6px;
  height: $control-height-sm - 6px;
  margin: 3px;
  background: $white-color;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(#000000, 0.25);
  transition: all 200ms ease;
}
