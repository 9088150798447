@import "styles/common.scss";
.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $offset-32;
  height: $offset-32;
  margin-right: $offset-16;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  cursor: move;
}