@import "styles/common.scss";
.root {
  display: block;
  margin: 16px 0;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.fixed {
  height: 64px;

  .container {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $offset-8 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
}
