@import "styles/common.scss";
.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.number {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.delimiter {
  margin: 0 $offset-16;
  font-weight: bold;
  font-size: 1.2em;
}
