$breakpoint-tablet: 568px;
$breakpoint-tablet-portrait: 768px;
$breakpoint-tablet-landscape: 1024px;
$breakpoint-desktop: 1200px;
$breakpoint-large: 1600px;

@mixin media-mobile {
  @media (min-width: 0) and (max-width: $breakpoint-tablet - 1px) {
    @content;
  }
}

@mixin media-tablet {
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop - 1px) {
    @content;
  }
}

@mixin media-except-mobile {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin media-desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin media-large {
  @media (min-width: $breakpoint-large) {
    @content;
  }
}

